import React, { useState } from 'react';

const Sidebar = () => {
  // Initialize all categories as expanded by default
  const [expanded, setExpanded] = useState({
    electronics: true,
    fashion: true,
    home: true,
  });

  const toggleExpand = (category) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [category]: !prevExpanded[category],
    }));
  };

  return (
    <div className="sidebar">
      <img alt='50PercentOff' className="fiftypercent" src={require('../static/gifs/shopify/50percent.gif')} />
      <u>
        <ul className="sidebar-list">
          <li>
            <button onClick={() => toggleExpand('electronics')}>
              Unisex Fashion
            </button>
            {expanded['electronics'] && (
              <ul className="sub-list">
                <li>Tee Shirts for Unisex</li>
                <li>Pants for Unisex</li>
                <li>Sweaters for Unisex</li>
                <li>Long Sleeve Tee Shirts for Unisex</li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => toggleExpand('fashion')}>Men's Fashion</button>
            {expanded['fashion'] && (
              <ul className="sub-list">
                <li>Tee Shirts for Men</li>
                <li>Pants for Men</li>
                <li>Sweaters for Men</li>
                <li>Long Sleeve Tee Shirts for Men</li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => toggleExpand('home')}>Women's Fashion</button>
            {expanded['home'] && (
              <ul className="sub-list">
                <li>Tee Shirts for Women</li>
                <li>Pants for Women</li>
                <li>Sweaters for Women</li>
                <li>Long Sleeve Tee Shirts for Women</li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => toggleExpand('acc')}>Accessories</button>
            {expanded['acc'] && (
              <ul className="sub-list">
                <li>Hats</li>
                <li>Vynls</li>
                <li>CD's/Tapes</li>
              </ul>
            )}
          </li>
        </ul>
      </u>
      <img alt='saleimage' src={require('../static/gifs/shopify/saleimage.gif')} />
    </div>
  );
};

export default Sidebar;
