import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { parseBuffer } from 'music-metadata';
import { Buffer } from 'buffer';

const fetchWithTimeout = (url, options = {}, timeout = 3000) => {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), timeout)
        )
    ]);
};

// Function to retry fetching with adaptive timeout (backoff on each failure)
const fetchWithRetry = async (url, retries = 3, initialTimeout = 3000) => {
    let timeout = initialTimeout; // Start with an initial small timeout (3 seconds)

    for (let i = 0; i < retries; i++) {
        try {
            const res = await fetchWithTimeout(url, {}, timeout);
            if (res.ok) {
                return res;
            }
            throw new Error(`Request failed with status ${res.status}`);
        } catch (error) {
            if (i === retries - 1) {
                throw new Error(`Network request failed after ${retries} attempts`);
            }
            // Increase the timeout (backoff) after each failed attempt
            timeout *= 2;
            console.warn(`Retrying with timeout: ${timeout / 1000} seconds...`);
        }
    }
};



export default function MediaList({ props }) {
    const { tracks, handleClick } = props;
    const [listOfMetaData, setListOfMetaData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isRetrying, setIsRetrying] = useState(false);

    // Cache to store track metadata
    const cache = useRef({});


    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                setIsLoading(true);
                setError(null);

                const metadataPromises = tracks.map(async (track) => {
                    if (cache.current[track]) {
                        // If metadata is cached, use it
                        return cache.current[track];
                    } else {
                        // Otherwise, fetch metadata with retry and cache it
                        const res = await fetchWithRetry(track, 3, 10000);
                        const arrayBuffer = await res.arrayBuffer();
                        const metadata = await parseBuffer(Buffer.from(arrayBuffer), 'audio/mpeg')
                            .catch((e) => {
                                console.error('Failed to parse metadata:', e);
                            });

                        // Store the fetched metadata in the cache
                        cache.current[track] = metadata;
                        return metadata;
                    }
                });

                const metadataList = await Promise.all(metadataPromises);
                setListOfMetaData(metadataList);
            } catch (error) {
                setError('Unable to load content due to network issues. Please try again later.');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMetadata();
    }, [tracks]);

    // Retry fetching metadata
    const handleRetry = async () => {
        setIsRetrying(true);
        setError(null);
        setIsLoading(true);

        try {
            const metadataPromises = tracks.map(async (track) => {
                if (cache.current[track]) {
                    // Return cached metadata
                    return cache.current[track];
                } else {
                    // Fetch and cache metadata if not cached
                    const res = await fetchWithRetry(track, 3, 10000);
                    const arrayBuffer = await res.arrayBuffer();
                    const metadata = await parseBuffer(Buffer.from(arrayBuffer), 'audio/mpeg')
                        .catch((e) => {
                            console.error('Failed to parse metadata:', e);
                        });
                    cache.current[track] = metadata;
                    return metadata;
                }
            });

            const metadataList = await Promise.all(metadataPromises);
            setListOfMetaData(metadataList);
        } catch (error) {
            setError('Failed to load metadata after retries. Please check your connection.');
        } finally {
            setIsLoading(false);
            setIsRetrying(false);
        }
    };

    if (isLoading) {
        return <div style={{ width: '70vw', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </div> // Show a loading message while fetching
    }

    if (error) {
        return (
            <div style={{ width: '70vw', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p>{error}</p>
                {!isRetrying && (
                    <button onClick={handleRetry}>Retry</button>  // Retry button in case of an error
                )}
            </div>
        );
    }



    return (
        <div className="media-list" >
            <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#ebe8db', textAlign: 'left', }}>
                <h1 style={{ paddingLeft: '1vw', width: '50vw' }}>Name</h1>
                <h1 style={{ paddingLeft: '2.5vw', paddingRight: '1vw' }} >Type</h1>
                <h1 style={{}}>Time  </h1>
            </div>
            <div style={{ height: '50%', maxHeight: '20vh', overflowY: 'scroll' }}>
                {listOfMetaData.map((item, index) => {

                    return (<MediaItem key={index} index={index} item={item} onClick={handleClick} />)

                })}

            </div>


        </div>
    );
};

function MediaItem({ item, index, onClick }) {

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = parseInt(totalSeconds % 60);
        return `${minutes}:${seconds}`;
    }
    const getBgColor = (index) => {
        if (index % 2 === 0) {
            return { backgroundColor: '#eef' };
        } else {
            return { backgroundColor: '#ffffff' };
        }
    };
    return (
        <div className="media-list-item" style={{ ...getBgColor(index) }} onClick={() => onClick(index)} >
            <>
                <h1 style={{ width: '50vw', paddingLeft: '1vw' }}>{item?.common?.title}</h1>
                <img style={{ height: '2vw', paddingTop: '0.75vw' }} src={require('../../static/imgs/music/cd-player.png')} alt="play" />
            </>
            <h1>mp3</h1>
            <h1 style={{ paddingRight: '1vw' }}>{formatTime(item?.format?.duration)}</h1>
            {/* <h1>{item.speed}</h1>
            <h1>{item.bitrate}</h1> */}
        </div>
    );
}

