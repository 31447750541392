import greeenArrow from '../../static/imgs/shopify/greenarrow.png';
import flyingPlane from '../../static/gifs/shopify/flyingplane.gif';
import spinningEarth from '../../static/gifs/shopify/spinningearth.gif';
import ATCButton from '../../static/imgs/shopify/addtocart.png';
import ClothesModal from '../../components/clothesModal';
import useWindowDimensions from '../../utils/screensize';
import React from 'react';


/**
 * Renders a single product in the shop page.
 * @param {object} props - Props passed from the parent component.
 * @param {string} props.key - The unique key of the product.
 * @param {string} props.img - The image URL of the product.
 * @param {string} props.productName - The name of the product.
 * @param {object} props.product - The product object.
 * @param {object} props.addItemToCart - The function to add the item to the cart.
 * @returns {ReactElement} The product component.
 */
export default function Product({ props }) {
    const { width } = useWindowDimensions();
    const [variantSelected, setVariantSelected] = React.useState(0);

    return (
        <div className="product">
            <div className="productImageContainer">
                <ClothesModal
                    key={props.key}
                    props={{
                        inStock: props?.product?.node?.availableForSale,
                        src: props.img,
                        handleATC: (id) => {
                            if (props.addItemToCart) {
                                props.addItemToCart(id, 1);
                            }
                        },
                        variants: props.product?.node?.variants,
                        colors: ["red", "blue", "green"],
                        sizes: ["S", "M", "L", "XL"],
                        openText: <img alt='product' className="productImage" src={props.img} />,
                        title: props.productName,
                        description: props.product?.node?.description,
                        price: props?.product?.node?.priceRange?.minVariantPrice?.amount
                    }} />
            </div>
            <div className="productDescription">
                {width < 1200 &&
                    <>
                        <div style={{ fontSize: '5vw', fontWeight: 'bold', overflowWrap: "break-word", textWrap: "wrap" }}> {props.productName}  </div>
                        <h2> ${props?.product?.node?.priceRange?.minVariantPrice?.amount}0</h2>
                        {!props?.product?.node?.availableForSale ? <u className="flash-color-text" style={{ fontSize: "3vw", color: "red", overflowWrap: "break-word", textWrap: "wrap" }}>SOLD OUT</u>
                            : <u className="flash-color-text" style={{ fontSize: "3vw", color: "green", overflowWrap: "break-word", textWrap: "wrap" }}> IN STOCK</u>}
                        <ClothesModal
                            key={props.key}
                            props={{
                                inStock: props?.product?.node?.availableForSale,
                                src: props.img,
                                handleATC: (id) => {
                                    if (props.addItemToCart) {
                                        props.addItemToCart(id, 1);
                                    }
                                },
                                variants: props.product?.node?.variants,
                                openText: <img alt="green arrow" className="greenArrow" src={greeenArrow} />,
                                title: props.productName,
                                description: props.product?.node?.description,
                                price: props?.product?.node?.priceRange?.minVariantPrice?.amount
                            }} />
                    </>
                }
                {
                    width > 1200 ?
                        <div style={{ display: 'flex', flexDirection: 'column', overflowWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ fontSize: '25px', fontWeight: 'bold' }}>The {props.productName}  </div>
                                    <ClothesModal props={{
                                        src: props.img,
                                        inStock: props?.product?.node?.availableForSale,
                                        handleATC: (id) => {
                                            if (props.addItemToCart) {
                                                props.addItemToCart(id, 1);
                                            }
                                        },
                                        variants: props.product?.node?.variants,
                                        openText: <img alt="green arrow" className="greenArrow" src={greeenArrow} />,
                                        title: props.productName,
                                        description: props.product?.node?.description,
                                        price: props?.product?.node?.priceRange?.minVariantPrice?.amount

                                    }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', overflow: "hidden" }}>

                                    <img alt="flying plane" className="flyingPlane" src={flyingPlane} />
                                    <img alt="spinning earth" className="spinningEarth" src={spinningEarth} />
                                </div>

                            </div>
                            {props?.product?.node?.availableForSale ?

                                <u className="flash-color-text" style={{ fontSize: "1.5vw", color: "blue", overflowWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>SELECT YOUR SIZE AND COLOR TO ADD {props.productName} TO YOUR CART !</u>
                                :
                                <u className="flash-color-text" style={{ fontSize: "1.5vw", color: "red", overflowWrap: "break-word" }} > SOLD OUT</u>

                            }


                        </div>
                        : <></>
                }
                <br />

                {width > 1200 &&
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

                            {/* <Select className='product-options-selector' options={options} /> */}

                            {props.product?.node?.variants && props.product?.node?.variants?.edges?.length > 1 &&
                                <div className="product-options-selector-container">
                                    Options:
                                    <select onChange={e => { setVariantSelected(e.target.value) }} className="product-options-selector">
                                        {props.product?.node?.variants?.edges.map((node, index) => {
                                            const variant = node.node;
                                            return <option key={index} value={index} >{variant.title}</option>
                                        })}
                                    </select>
                                </div>

                            }


                        </div>

                        <img
                            alt="addToCart"
                            onClick={() => {
                                if (props.addItemToCart && props?.product?.node?.availableForSale) {
                                    console.log('Adding')
                                    props.addItemToCart(props.product?.node?.variants?.edges?.[variantSelected].node.id, 1);
                                    props.handleSnackBarOpen();
                                }
                            }}
                            className="addToCartBtn" src={ATCButton} />
                        <br />
                    </div>
                }

                <p style={{ overflowWrap: "break-word", textWrap: "wrap" }} className="text-black">{props.product?.node?.description}</p>
                <br />
                <p><u>now shipping GLOBALLY ! :)</u></p>

            </div>
        </div >
    );
}