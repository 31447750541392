import { Timer } from 'react-soundplayer/components';
import React, { useState } from 'react';
import './win95.css';
import useWindowDimensions from '../../utils/screensize';

export default function PlayerDisplay({ props }) {
    const { width } = useWindowDimensions();
    let title;
    let artist;
    let length;
    if (props.metadata) {
        title = props.metadata?.common?.title;
        artist = props.metadata?.common?.artist;
        length = props.metadata?.format?.duration;


    }
    const [curTime, setCurTime] = useState(0);
    const audioPlayer = document.getElementById('audio');

    if (audioPlayer) {
        audioPlayer.addEventListener('timeupdate', function () {
            var currentTimeMs = audioPlayer.currentTime;
            setCurTime(currentTimeMs);
        }, false);
    }

    return (
        <div className="clock-display">
            <div className="player-dispay-info-container">
                <div className='player-display-thumbnail'>
                    <img id='thumbnail-img' src={require('../../static/imgs/music/album.png')} alt="play" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '1vw', alignItems: 'flex-start' }}>
                    {width > 768 && <p>
                        {artist}

                    </p>}
                    <p style={{ textDecoration: 'underline' }}>
                        {title}

                    </p>

                </div>

            </div>

            <span className="text-clock-display" >
                <Timer className="custom-player-timer"
                    duration={length}
                    currentTime={curTime}
                    {...props} />

            </span>




        </div>
    )
}