import React from 'react';

const viewListData = {
    'Apple': {
        link: "https://music.apple.com/us/album/1767485700?ls=1&app=music&at=1010lIJf&ct=LFV_2545fbdcf3132d61e85a35aba0246f17&itscg=30440&itsct=catchall_p2&lId=211295165&cId=none&sr=2&src=Linkfire"
    },
    'Amazon': {
        link: "http://music.amazon.com/albums/B0DGJNS35F?tag=interscoperec-20&ie=UTF8&linkCode=as2&ascsubtag=2545fbdcf3132d61e85a35aba0246f17&ref=dmm_acq_soc_us_u_lfire_lp_x_2545fbdcf3132d61e85a35aba0246f17&fbclid=PAZXh0bgNhZW0CMTEAAaa4XHpTE8f0Mlch4nwMx1v1DJUV8T_GXeExfI3p2xaktgBNaCN5SqW5LIE_aem_VcDJRBTeqjQX257nc6i97g"
    },
    'Spotify': {
        link: "https://open.spotify.com/album/4IkYiFIH6Le7puR479Ovk1?go=1&utm_campaign=fAfTD_96_fmly&utm_source=Original_Original&utm_medium=Original&utm_content=00ef8207-9d8d-413d-9694-61734c225df3_none_Jordan%2BWard_100527119_none_none_20240917_http%253A%252F%252Flocalhost%253A3000%252F_interscope-records_US_JordanWardxJoony.lnk.to%2FJRNY&fbclid=PAZXh0bgNhZW0CMTEAAaa4XHpTE8f0Mlch4nwMx1v1DJUV8T_GXeExfI3p2xaktgBNaCN5SqW5LIE_aem_VcDJRBTeqjQX257nc6i97g"
    }
}
export default function ViewList({ props }) {
    return (
        <div className="disc-list" >
            <div style={{ height: '50%', maxHeight: '20vh', overflowY: 'scroll' }}>
                {Object.entries(viewListData).map(([key, value]) => {

                    return (<ViewItem key={key} listKey={key} value={value} />)

                })}

            </div>


        </div>
    );
};

function ViewItem({ value, listKey }) {
    const getBgColor = () => {
        return { backgroundColor: '#BFBFBF' };

    };

    return (
        <div className="music-player-menu-item" style={{ ...getBgColor(), borderBottom: '1px solid black' }}  >
            <>
                <a href={value.link} target='_blank' rel="noreferrer">
                    <h1 style={{ width: '10vw', paddingLeft: '1vw' }}>{listKey}</h1>
                </a>
            </>
        </div>
    );
}

