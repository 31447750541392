import closeIcon from '../static/imgs/windowsIcons/close.png';
const WindowHead = (props) => {
    return (
        <div className="windows-header">
            <div className="windows-title">
                <span className="cd-player" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {props.title}
                    <div style={{ height: '1vw', paddingTop: '0.25vw' }}>
                        {props.includeCloseButton ? <img height="1px" src={closeIcon} alt="close" onClick={props.onClose} /> : <></>}

                    </div>
                </div>



            </div>
        </div>
    );
};

export default function Window({ props, children }) {
    return (
        <div className='windows-box' >
            <div className="windows">
                <WindowHead title={props.windowTitle} includeCloseButton={props.includeCloseButton} onClose={props.onClose} style={{ position: 'fixed', top: '0px', left: '0px', zIndex: '1' }} />
            </div>
            <div style={props.style}>
                {children}

            </div>

        </div>
    );
}