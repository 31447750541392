import React from 'react';

const viewListData = {
    'jealousy': {
        link: "https://www.youtube.com/embed/EOvtSz1yyHc",
    },
    'burning rubber': {
        link: "https://www.youtube.com/embed/soSdGgMs9oA?si=ABvC3xpMJ76AK8BP",
    },
    'embargo': {
        link: "https://www.youtube.com/embed/a1yFP4sX6EA?si=hLGylovSYEXsDNMq",
    },
    'homebody': {
        link: "https://www.youtube.com/embed/_ZqgxUaZ2fE?si=qvgGhtGcxRMSBkEk",
    },
    'one too many times': {
        link: "https://www.youtube.com/embed/7MC2M3q5g5I?si=-oaEWmAkyGVh16Zb",
    },
}
export default function DiscList({ props }) {
    return (
        <div className="disc-list" >
            <div style={{ height: '50%', maxHeight: '20vh', overflowY: 'scroll' }}>
                {Object.entries(viewListData).map(([key, value]) => {

                    return (<ViewItem key={key} name={key} link={value} handleClick={props.handleClick} />)

                })}

            </div>


        </div>
    );
};

function ViewItem({ link, name, handleClick }) {
    const getBgColor = () => {
        return { backgroundColor: '#BFBFBF' };

    };

    return (
        <div className="music-player-menu-item" style={{ ...getBgColor(), borderBottom: '1px solid black' }}  >
            <>
                <h1 style={{ width: '10vw', paddingLeft: '1vw' }} onClick={() => handleClick(link.link)}>{name}</h1>

            </>
        </div>
    );
}

