

import Shopify from './Shopify';
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react';
import { keys } from '../../utils/keys';
import React, { useEffect, useState } from 'react';
import LinktreeWindow from "../../components/linktreeWindow"

export default function Merch() {
    const [closedLinks, setClosedLinks] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [time, setTime] = useState(new Date().getTime() / 1000);

    useEffect(() => {
        // Function to execute the desired logic
        const checkTimeAndUpdateLinks = () => {
            var curTime = new Date().getTime() / 1000;
            if (curTime - time >= 20 && !closedLinks) {
                setTime(curTime);  // Update the time to the current time
                setShowLinks(true); // Show the links
            }
        };

        // Set an interval to run the function every 8 seconds
        const intervalId = setInterval(checkTimeAndUpdateLinks, 8000); // 8000ms = 8 seconds

        // Cleanup: Clear the interval when the component unmounts or the effect re-runs
        return () => clearInterval(intervalId);
    }, [time, closedLinks]);

    useEffect(() => {
        if (closedLinks) {
            const timeoutId = setTimeout(() => {
                setShowLinks(true);
                setClosedLinks(false); // Reset so it can show again after closing
            }, 20000); // Reappear after 8 seconds

            return () => clearTimeout(timeoutId); // Clear timeout if the component unmounts
        }
    }, [closedLinks]);
    return (

        <ShopifyProvider
            storeDomain={keys.REACT_APP_PUBLIC_STORE_DOMAIN}
            storefrontToken={keys.REACT_APP_PUBLIC_STOREFRONT_API_TOKEN}
            storefrontApiVersion="2024-07"
            countryIsoCode="US"
            languageIsoCode="EN"
        >
            {showLinks && !closedLinks && (

                <div style={{ zIndex: '1000' }}>
                    <LinktreeWindow
                        props={{
                            onClose: () => {
                                setClosedLinks(true);
                                setShowLinks(false); // Hide when closed, will reappear after 8 seconds
                            },
                        }}
                    />
                </div>
            )}
            <CartProvider>

                <Shopify />
            </CartProvider>


        </ShopifyProvider>
    )
}
