import Controls from './controls';
import PlayerDisplay from './player-display';
import { useEffect, useState, useCallback } from 'react';
import MediaList from './media-list';
import Window from '../../components/Window';
import LinktreeWindow from '../../components/linktreeWindow';
import DiscList from './disc-list';
import ViewList from './view-list';
import { parseBuffer } from 'music-metadata';
import { Buffer } from 'buffer';



const audioFiles = [
    '../../../JRNY/jrny radio.mp3',
    '../../../JRNY/homebody.mp3',
    '../../../JRNY/jealousy.mp3',
    '../../../JRNY/one too many times.mp3',
    '../../../JRNY/burning rubber.mp3',
    '../../../JRNY/embargo.mp3'
]

export default function CustomPlayer({ track }, props) {
    const [metadata, setMetadata] = useState({ title: '', artist: '' });
    const [currentTrack, setCurrentTrack] = useState(0);
    const [closedLinks, setClosedLinks] = useState(false);
    const [duration, setDuration] = useState(0);
    const [showLinks, setShowLinks] = useState(false);
    const [showDiscList, setShowDiscList] = useState(false);
    const [showViewList, setShowViewList] = useState(false);

    const audioPlayer = document.getElementById('audio');
    const handleNext = useCallback(() => {
        if (currentTrack < audioFiles.length - 1) {
            setCurrentTrack(currentTrack + 1);
        } else {
            setCurrentTrack(0);
        }
    }, [currentTrack]);

    // Handle audio player time updates
    useEffect(() => {
        const handleTimeUpdate = () => {
            const currentTimeMs = audioPlayer?.currentTime;
            if (currentTimeMs >= 8 && !closedLinks) {
                setShowLinks(true);
            }

            if (currentTimeMs >= duration - .01) {
                handleNext();
            }
        };

        if (audioPlayer) {
            audioPlayer.addEventListener('timeupdate', handleTimeUpdate, false);
        }

        return () => {
            if (audioPlayer) {
                audioPlayer.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [audioPlayer, closedLinks, duration, handleNext]);

    const handlePause = () => {
        audioPlayer.pause();
    };

    const handlePlay = useCallback(() => {
        audioPlayer.play().catch(err => { });
    }, [audioPlayer]);


    const handlePrevious = () => {
        if (currentTrack > 0) {
            setCurrentTrack(currentTrack - 1);
        } else {
            setCurrentTrack(audioFiles.length - 1);
        }
    };

    const handleMediaListClick = (track_num) => {
        setShowDiscList(false);
        setCurrentTrack(track_num);
    };

    // Reset the `showLinks` state 8 seconds after the window is closed
    useEffect(() => {
        if (closedLinks) {
            const timeoutId = setTimeout(() => {
                setShowLinks(true);
                setClosedLinks(false); // Reset so it can show again after closing
            }, 15000); // Reappear after 8 seconds

            return () => clearTimeout(timeoutId); // Clear timeout if the component unmounts
        }
    }, [closedLinks]);

    // Fetch metadata for the current track
    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const res = await fetch(audioFiles[currentTrack]);
                const arrayBuffer = await res.arrayBuffer();
                const metadata = await parseBuffer(Buffer.from(arrayBuffer), 'audio/mpeg')
                    .catch((e) => {
                        console.error('Failed to parse metadata:', e);
                    });
                setMetadata(metadata);
                setDuration(metadata?.format?.duration);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        fetchMetadata();
        if (audioPlayer) {
            handlePlay();
        }
    }, [currentTrack, audioPlayer, handlePlay]);

    return (
        <Window props={{
            style: {
                width: "75vw",
                height: "100%",
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto'
            },
            windowTitle: `${metadata?.common?.title || ''} - ${metadata?.common?.artist || ''}`
        }}>
            <div className="windows-container">
                {showLinks && !closedLinks && (
                    <LinktreeWindow
                        props={{
                            onClose: () => {
                                setClosedLinks(true);
                                setShowLinks(false); // Hide when closed, will reappear after 8 seconds
                            },
                        }}
                    />
                )}
                <ul className="windows-toolbar">
                    <li className="toolbar-item" onClick={() => { setShowDiscList(!showDiscList); setShowViewList(false) }}> <p className="windows-link">Disc </p> </li>
                    <li className="toolbar-item" onClick={() => { setShowViewList(!showViewList); setShowDiscList(false) }}> <p className="windows-link">View </p></li>
                    <li className="toolbar-item" onClick={() => { setShowLinks(true); setClosedLinks(false) }}> <p className="windows-link">Option </p> </li>
                    <li className="toolbar-item" onClick={() => { setShowLinks(true); setClosedLinks(false) }}> <p className="windows-link">Help </p></li>
                </ul>
                {showDiscList && <DiscList
                    props={{ handleClick: handleMediaListClick, tracks: audioFiles }}
                />}
                {showViewList && <ViewList />}
            </div>

            <div className='player-container'>
                <div>
                    <PlayerDisplay props={{ track: track, metadata: metadata }} />
                </div>
                <audio id="audio" autoPlay src={audioFiles[currentTrack]}></audio>
                <div>
                    <Controls
                        props={{ handlePlay, handlePause, handleNext, handlePrevious }}
                    />
                </div>
                <div style={{ backgroundColor: '#eeeeff' }}>
                    <MediaList
                        props={{ handleClick: handleMediaListClick, tracks: audioFiles }}
                    />
                </div>
            </div>
        </Window>
    );
}
