import { hydrogenClient } from '../utils/shopify-client';
import { GET_PRODUCTS_QUERY, GET_PRODCUT_QUERY } from '../lib/product-queries';

async function getProducts() {
    // Get the Storefront API url
    const response = await fetch(hydrogenClient.getStorefrontApiUrl(), {
        body: JSON.stringify({
            query: GET_PRODUCTS_QUERY,
        }),
        // Generate the headers using the private token. Additionally, you can pass in the buyer's IP address from the request object to help prevent bad actors from overloading your store.
        headers: hydrogenClient.getPublicTokenHeaders(),
        method: 'POST',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json = await response.json();

    return { products: json };
}


async function getProductById(id) {
    // Get the Storefront API url
    const response = await fetch(hydrogenClient.getStorefrontApiUrl(), {
        body: JSON.stringify({
            query: GET_PRODCUT_QUERY,
            variables: {
                id: id,
            },
        }),
        // Generate the headers using the private token. Additionally, you can pass in the buyer's IP address from the request object to help prevent bad actors from overloading your store.
        headers: hydrogenClient.getPublicTokenHeaders(),
        method: 'POST',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json = await response.json();

    return { products: json };
}

export {
    getProducts,
    getProductById
}