import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CartLine from './cartLine';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding: 2,
    overflow: 'auto',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
};

function CartCount(count) {
    return (
        <div className="cartCount">x{count.count}</div>
    )
}


export default function CartModal({ props }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }} onClick={handleOpen}>
                <img alt="cart" className="cartImg" src={require('../static/imgs/shopify/cart.png')} />
                <CartCount count={props.count} />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="div" gutterBottom>
                            Your Cart
                        </Typography>
                        <div style={{ overflow: 'auto', maxHeight: '300px' }}>

                            {props.cart.map((item) => (
                                <div key={item.id}>
                                    <CartLine item={item} linesRemove={props.linesRemove} />
                                </div>
                            ))}
                        </div>
                        <Button onClick={props.handleCheckout}>
                            Checkout
                        </Button>


                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}