import Footer from "../../components/Footer";
import Window from '../../components/Window';
import { getDesktopIcons, desktopIcons } from "../../components/desktopIcon";

export default function Videos() {
    return (
        <div>
            <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                {getDesktopIcons(desktopIcons)}

                <Window props={{
                    windowTitle: "Tumblr",
                    style: {
                        width: "75vw",
                        height: "75vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto'
                    }
                }} >
                    <div className="iframe-container">
                        <iframe
                            src="https://jrnyradio.tumblr.com/"
                            frameBorder="0"
                            allowFullScreen
                            title="Tumblr Page Embed"
                        ></iframe>
                    </div>
                </Window>
            </div>
            <Footer />

        </div>
    );
}