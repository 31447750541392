const desktopIcons = {

    'Home': {
        title: 'Home',
        imgSrc: require('../static/imgs/windowsIcons/desktop.ico'),
        path: '/'
    },
    'Shopping': {
        title: 'Merch',
        imgSrc: require('../static/imgs/windowsIcons/heart.png'),
        path: '/merch'
    },
    'Tumblr': {
        title: 'Tumblr',
        imgSrc: require('../static/imgs/windowsIcons/tumblr.png'),
        path: '/videos'
    },
    'Vlog': {
        title: 'Vlog',
        imgSrc: require('../static/imgs/windowsIcons/dvd.ico'),
        path: '/vlog'
    },
    'Music': {
        title: 'Music',
        imgSrc: require('../static/imgs/windowsIcons/winamp.png'),
        path: '/music'

    },

}


function DesktopIcon({ props }) {
    return (
        <a href={props.path}>
            <div className="desktopIconContainer">
                <img alt="icon" src={props.imgSrc} className="desktopIcon" />
                <div style={{}}>{props.title}</div>
            </div>
        </a>
    );
}

function getDesktopIcons(icons) {

    return (<div className="desktopIconsContainer">
        {Object.entries(icons).map(([key, value]) => {
            return (
                <DesktopIcon
                    key={key}
                    props={{
                        title: value.title,
                        imgSrc: value.imgSrc,
                        path: value.path

                    }}
                />
            )
        })}
    </div>);
}

export {
    getDesktopIcons,
    DesktopIcon,
    desktopIcons
}