const timezoneToCountryMap = {
    'Pacific/Honolulu': 'US',
    'America/Anchorage': 'US',
    'America/Los_Angeles': 'US',
    'America/Tijuana': 'MX',
    'America/Vancouver': 'CA',
    'America/Denver': 'US',
    'America/Phoenix': 'US',
    'America/Edmonton': 'CA',
    'America/Chicago': 'US',
    'America/Winnipeg': 'CA',
    'America/Mexico_City': 'MX',
    'America/New_York': 'US',
    'America/Toronto': 'CA',
    'America/Lima': 'PE',
    'America/Bogota': 'CO',
    'America/Sao_Paulo': 'BR',
    'America/Argentina/Buenos_Aires': 'AR',
    'America/Caracas': 'VE',
    'America/Santiago': 'CL',
    'Atlantic/Reykjavik': 'IS',
    'Europe/Lisbon': 'PT',
    'Europe/London': 'GB',
    'Europe/Dublin': 'IE',
    'Europe/Paris': 'FR',
    'Europe/Berlin': 'DE',
    'Europe/Madrid': 'ES',
    'Europe/Rome': 'IT',
    'Europe/Amsterdam': 'NL',
    'Europe/Brussels': 'BE',
    'Europe/Zurich': 'CH',
    'Europe/Stockholm': 'SE',
    'Europe/Warsaw': 'PL',
    'Europe/Budapest': 'HU',
    'Europe/Vienna': 'AT',
    'Europe/Prague': 'CZ',
    'Europe/Athens': 'GR',
    'Europe/Istanbul': 'TR',
    'Europe/Moscow': 'RU',
    'Asia/Dubai': 'AE',
    'Asia/Karachi': 'PK',
    'Asia/Kolkata': 'IN',
    'Asia/Colombo': 'LK',
    'Asia/Kathmandu': 'NP',
    'Asia/Dhaka': 'BD',
    'Asia/Jakarta': 'ID',
    'Asia/Bangkok': 'TH',
    'Asia/Manila': 'PH',
    'Asia/Singapore': 'SG',
    'Asia/Hong_Kong': 'HK',
    'Asia/Taipei': 'TW',
    'Asia/Seoul': 'KR',
    'Asia/Tokyo': 'JP',
    'Australia/Sydney': 'AU',
    'Australia/Melbourne': 'AU',
    'Australia/Brisbane': 'AU',
    'Australia/Adelaide': 'AU',
    'Australia/Perth': 'AU',
    'Pacific/Auckland': 'NZ',
    'Pacific/Fiji': 'FJ',
    'Pacific/Tahiti': 'PF',
    'Africa/Cairo': 'EG',
    'Africa/Johannesburg': 'ZA',
    'Africa/Lagos': 'NG',
    'Africa/Nairobi': 'KE',
    'Africa/Casablanca': 'MA',
    'Africa/Algiers': 'DZ',
    'Africa/Accra': 'GH',
    'Indian/Mauritius': 'MU',
    'Indian/Maldives': 'MV',
    'Indian/Chagos': 'IO',
    'Indian/Reunion': 'RE',
    'Atlantic/Azores': 'PT',
    'Atlantic/Canary': 'ES',
    'Asia/Yerevan': 'AM',
    'Asia/Tehran': 'IR',
    'Asia/Tbilisi': 'GE',
    'Asia/Kabul': 'AF',
    'Asia/Yekaterinburg': 'RU',
    'Asia/Krasnoyarsk': 'RU',
    'Asia/Novosibirsk': 'RU',
    'Asia/Vladivostok': 'RU',
    'Asia/Magadan': 'RU',
    'Pacific/Guam': 'GU',
    'Pacific/Samoa': 'WS',
    'Pacific/Noumea': 'NC',
    'Europe/Kiev': 'UA',
    'Europe/Minsk': 'BY',
    'Europe/Vilnius': 'LT',
    'Europe/Riga': 'LV',
    'Europe/Tallinn': 'EE',
    'Asia/Riyadh': 'SA',
    'Asia/Kuwait': 'KW',
    'Asia/Amman': 'JO',
    'Asia/Beirut': 'LB',
    'Asia/Baghdad': 'IQ',
    'Asia/Jerusalem': 'IL',
    'Asia/Damascus': 'SY',
    'Asia/Doha': 'QA',
    'Asia/Muscat': 'OM',
    'Antarctica/McMurdo': 'AQ',
    'Pacific/Easter': 'CL',
    'Atlantic/Bermuda': 'BM',
    'America/Halifax': 'CA',
    'America/Moncton': 'CA',
    'America/St_Johns': 'CA',
    'America/Barbados': 'BB',
    'America/Port_of_Spain': 'TT',
    'America/Montevideo': 'UY',
    'Pacific/Pago_Pago': 'AS',
    'Pacific/Guadalcanal': 'SB',
    'Pacific/Kiritimati': 'KI'
};
function getCountryCodeFromTimezone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('User timezone:', timeZone);

    const countryCode = timezoneToCountryMap[timeZone] || 'US';

    console.log('Inferred country code from timezone:', countryCode);
    return countryCode;
}

export {
    getCountryCodeFromTimezone
}