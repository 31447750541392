import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
export default function CartLine({ item, linesRemove }) {


    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <img style={{ width: 100, height: 100, padding: 5 }} src={item.merchandise.image?.url} alt="product-img" />

            <h1 style={{ wordWrap: 'break-word', width: '30vw' }}>{item.merchandise.product.title}</h1>
            <div style={{ padding: 5, backgroundColor: 'gray' }}>{item.quantity}</div>
            <DeleteIcon onClick={() => linesRemove([item.id])} />
        </div>
    );
}