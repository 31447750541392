import React, { useEffect, useState, useRef } from 'react';
import { parseBuffer } from 'music-metadata';
import { Buffer } from 'buffer';

export default function DiscList({ props }) {
    const { tracks, handleClick } = props;
    const [listOfMetaData, setListOfMetaData] = useState([]);
    const [areTracksSet, setAreTracksSet] = useState(false);
    const initialized = useRef(false)

    useEffect(() => {
        const fetchMetadata = async () => {
            if (initialized.current) return;
            try {
                initialized.current = true;
                for (let i = 0; i < tracks.length; i++) {
                    await fetch(tracks[i])
                        .then(async (res) => {
                            return await res.arrayBuffer();
                        })
                        .then(async (buffer) => {
                            await parseBuffer(Buffer.from(buffer), 'audio/mpeg')
                                .then((metadata) => {

                                    setListOfMetaData((prevList) => [...prevList, metadata]);
                                })
                                .catch((e) => {
                                    console.error('Failed to parse metadata:', e);
                                });
                        })
                }

            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
            setAreTracksSet(true);

        };

        fetchMetadata();


    }, [tracks]);



    return (
        <div className="disc-list" >
            <div style={{ height: '50%', maxHeight: '20vh', overflowY: 'scroll' }}>
                {areTracksSet && listOfMetaData.map((item, index) => {

                    return (<DiscItem key={index} index={index} item={item} onClick={handleClick} />)

                })}

            </div>


        </div>
    );
};

function DiscItem({ item, index, onClick }) {
    console.log(item.common);
    const getBgColor = (index) => {
        return { backgroundColor: '#BFBFBF' };

    };
    return (
        <div className="music-player-menu-item" style={{ ...getBgColor(index), borderBottom: '1px solid black' }} onClick={() => onClick(index)} >
            <>
                <h1 style={{ width: '25vw', paddingLeft: '1vw' }}>{item?.common?.title}</h1>
            </>
        </div>
    );
}

