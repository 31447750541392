import Window from "./Window";
export default function LinktreeWindow({ props }) {

    return (
        <div style={{ position: 'absolute', top: '0px', right: '0px', zIndex: '1' }}>

            <Window props={{
                style: {

                    justifyContent: "center"
                },
                windowTitle: `ALERT!! CLICK HERE FOR MORE`, includeCloseButton: true,
                onClose: props.onClose

            }}>
                <div style={{ alignItems: 'center', overflow: 'auto' }}>
                    <iframe id="frame" title="frame" src="https://jordanwardxjoony.lnk.to/JRNY?fbclid=PAZXh0bgNhZW0CMTEAAaa4XHpTE8f0Mlch4nwMx1v1DJUV8T_GXeExfI3p2xaktgBNaCN5SqW5LIE_aem_VcDJRBTeqjQX257nc6i97g"
                        width="100%"
                        height="600px"
                        sandbox="allow-scripts allow-same-origin"
                    />
                    <div
                        onClick={() => window.open('https://jordanwardxjoony.lnk.to/JRNY?fbclid=PAZXh0bgNhZW0CMTEAAaa4XHpTE8f0Mlch4nwMx1v1DJUV8T_GXeExfI3p2xaktgBNaCN5SqW5LIE_aem_VcDJRBTeqjQX257nc6i97g', '_blank')}
                        style={{
                            position: 'absolute',
                            top: 40,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                            zIndex: '1'
                        }} />
                </div>


            </Window>
        </div >
    );
}