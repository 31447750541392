import { createStorefrontClient } from '@shopify/hydrogen-react';
import { keys } from './keys';

const hydrogenClient = createStorefrontClient({
    // load environment variables according to your framework and runtime
    storeDomain: keys.REACT_APP_PUBLIC_STORE_DOMAIN,
    storefrontApiVersion: '2024-07',

    publicStorefrontToken: keys.REACT_APP_PUBLIC_STOREFRONT_API_TOKEN,
});

export {
    hydrogenClient,
}
