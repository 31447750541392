import Footer from "../../components/Footer";
import { getDesktopIcons, desktopIcons } from "../../components/desktopIcon";
import React, { useEffect, useState } from 'react';
import LinktreeWindow from "../../components/linktreeWindow"
export default function Home() {
    const [closedLinks, setClosedLinks] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [time, setTime] = useState(new Date().getTime() / 1000);

    useEffect(() => {
        // Function to execute the desired logic
        const checkTimeAndUpdateLinks = () => {
            var curTime = new Date().getTime() / 1000;
            if (curTime - time >= 8 && !closedLinks) {
                setTime(curTime);  // Update the time to the current time
                setShowLinks(true); // Show the links
            }
        };

        // Set an interval to run the function every 8 seconds
        const intervalId = setInterval(checkTimeAndUpdateLinks, 8000); // 8000ms = 8 seconds

        // Cleanup: Clear the interval when the component unmounts or the effect re-runs
        return () => clearInterval(intervalId);
    }, [time, closedLinks]);

    useEffect(() => {
        if (closedLinks) {
            const timeoutId = setTimeout(() => {
                setShowLinks(true);
                setClosedLinks(false); // Reset so it can show again after closing
            }, 8000); // Reappear after 8 seconds

            return () => clearTimeout(timeoutId); // Clear timeout if the component unmounts
        }
    }, [closedLinks]);

    return (
        <div>
            <div className="HomeBckg">
                {getDesktopIcons(desktopIcons)}
                <div className="App-header">
                    {showLinks && !closedLinks && (
                        <LinktreeWindow
                            props={{
                                onClose: () => {
                                    setClosedLinks(true);
                                    setShowLinks(false); // Hide when closed, will reappear after 8 seconds
                                },
                            }}
                        />
                    )}
                    <img alt="logo" src={require('../../static/imgs/shopify/jrnylogo.webp')} className="jrny-title"></img>
                </div>
                <Footer />
            </div>

        </div>
    );
}