import React, { useState, useEffect } from 'react';
import startButton from '../static/imgs/windowsIcons/start.png';
import musicIcon from '../static/imgs/windowsIcons/winamp.png';
import homeIcon from '../static/imgs/windowsIcons/desktop.ico';
import merchIcon from '../static/imgs/windowsIcons/heart.png';
import tumblrIcon from '../static/imgs/windowsIcons/tumblr.png';
import videoIcon from '../static/imgs/windowsIcons/dvd.ico';

const windows = {
    "Home": {
        icon: homeIcon,
        title: "My Computer",
        path: "/"
    },
    "Merch": {
        icon: merchIcon,
        title: "Shopping",
        path: "/merch"
    },
    "Tumblr": {
        icon: tumblrIcon,
        title: "Tumblr",
        path: "/videos"
    },
    "Music": {
        icon: musicIcon,
        title: "Music",
        path: "/music"
    },
    "Vlog": {
        icon: videoIcon,
        title: "Vlog",
        path: "/vlog"
    }
}

export default function Footer() {
    const [time, setTime] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(timer); // Clean up the interval on component unmount
    }, []);

    const getMenuWindows = () => {
        return Object.entries(windows).map(([key, value]) => {
            return (
                <div
                    className="footer__window"
                    onClick={() => window.location.href = value.path}
                    key={key}
                >
                    <img className="footer__icon" src={value.icon} alt={value.title} />
                    <div className="footer__text">{value.title}</div>
                </div>
            );
        });
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <>
            <footer className="footer">
                <div
                    onClick={() => window.location.href = "/"}
                >
                    <img
                        onClick={() => window.location.href = "/"}
                        src={startButton}
                        alt="start"
                        className="footer__start"
                    />
                </div>
                {getMenuWindows()}

                {/* Time Widget */}
                <div className="footer__time" onClick={() => setModalOpen(true)}>
                    {formatTime(time)}
                </div>
            </footer>

            {/* Modal */}
            {modalOpen && (
                <div className="modal" onClick={() => setModalOpen(false)}>
                    <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                        <span className="modal__close" onClick={() => setModalOpen(false)}>&times;</span>
                        {formatTime(time)}
                        <p style={{ fontWeight: "bold" }}>Dev: <br />
                            Samiy & Nati   : ) </p>
                    </div>
                </div>
            )}
        </>
    );
}
