import CustomPlayer from "./player";
import Footer from "../../components/Footer";
import { getDesktopIcons, desktopIcons } from "../../components/desktopIcon";

export default function Music() {
    return (
        <div className="music-page-container">
            {getDesktopIcons(desktopIcons)}

            <div className="App-header">
                <CustomPlayer track={{ title: "One too Many Times", Artist: 'Joony & Jordan Ward', duration: 10000000 }} />


            </div>
            <Footer />

        </div>
    );
}