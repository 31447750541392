import prevButtonImg from '../../static/imgs/music/prev.png';
import playButtonImg from '../../static/imgs/music/play.png';
import pauseButtonImg from '../../static/imgs/music/pause.png';
import nextButtonImg from '../../static/imgs/music/next.png';

export default function Controls({ props }) {
    const { handlePlay, handlePause, handleNext, handlePrevious } = props;


    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button className="player-button" onClick={() => handlePrevious()}>
                <img className="player-button-icon" src={prevButtonImg} alt="prev" />
            </button>
            <button className="player-button"
                onClick={() => handlePlay()}>
                <img className="player-button-icon" src={playButtonImg} alt="play" />
            </button>
            <button className="player-button" onClick={() => handlePause()}>
                <img className="player-button-icon" src={pauseButtonImg} alt="pause" />
            </button>

            <button className="player-button" onClick={() => handleNext()}>
                <img className="player-button-icon" src={nextButtonImg} alt="next" />
            </button>

        </div>
    )

}