import React from 'react';
import Sidebar from '../../components/sidebar';
import Footer from "../../components/Footer";
import Window from '../../components/Window';
import SearchBar from '../../components/searchbar';
import useWindowDimensions from '../../utils/screensize';
import Product from './product';
import Backdrop from '@mui/material/Backdrop';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getProducts } from '../../data/getProducts';
import { useCart } from '@shopify/hydrogen-react';
import CartModal from '../../components/cartModal';
import Snackbar from '@mui/material/Snackbar';
import { getCountryCodeFromTimezone } from '../../utils/countryCode';
import { getDesktopIcons, desktopIcons } from "../../components/desktopIcon";
import ad2 from '../../static/imgs/shopify/ad2.jpg';
import ad3 from '../../static/imgs/shopify/ad3.PNG';
import ad6 from '../../static/imgs/shopify/ad6.jpg';


export default function Shopify() {
    const { width } = useWindowDimensions();
    const [buyerIdentity, setBuyerIdentity] = useState({});
    const [buyerIdentitySet, setBuyerIdentitySet] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { checkoutUrl, cartCreate, linesAdd, lines, status, linesRemove, totalQuantity } = useCart();
    const [toggleMenu, setToggleMenu] = React.useState(width > 1000);
    const [open, setOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    useEffect(() => {
        setBuyerIdentity({
            countryCode: getCountryCodeFromTimezone(),
        })
        setBuyerIdentitySet(true);
        getProducts()
            .then((data) => {
                setProducts(data.products.data.products.edges);
                setLoading(false);
            })
            .catch((error) => {
            });

    }, []);

    useEffect(() => {
        const createCartIfNotExists = async () => {
            if (!checkoutUrl && cartCreate && buyerIdentitySet) {
                try {
                    cartCreate({
                        lines: [],  // Optional lines for products
                        buyerIdentity: buyerIdentity
                    });
                } catch (error) {
                    console.error("Error creating cart:", error);
                }
            }
        };

        if (cartCreate) {
            createCartIfNotExists();
        }
    }, [cartCreate, checkoutUrl, buyerIdentitySet, buyerIdentity]);

    const handleSnackBarOpen = () => {
        setSnackbarOpen(true);
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const handleCheckout = () => {
        if (status === "idle") {
            window.open(checkoutUrl, "_blank");
        }
    }

    const toggleSidebar = () => {
        if (width < 700) {
            setOpen(!open);
        }
        setToggleMenu(!toggleMenu);
    }
    const resolveImage = (node) => {

        return node?.media?.edges[0]?.node?.image?.originalSrc;
    }

    const addItemToCart = (id) => {
        linesAdd([{ merchandiseId: id }])
    };
    const getListOfProducts = () => {
        return products.map((product) => {
            if (product !== undefined) {
                let imgSrc = resolveImage(product.node);
                if (imgSrc === undefined) {
                    imgSrc = require("../../static/imgs/clothes/cl1.jpg");
                }
                return (
                    <Product
                        key={product.node.id}
                        props={{
                            key: product.node.id,
                            product: product,
                            productName: product.node.title,
                            img: imgSrc,
                            addItemToCart: addItemToCart,
                            handleSnackBarOpen: handleSnackBarOpen,

                        }}
                    />
                )
            }
            return <></>;
        })
    }

    return (
        <>

            <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                {getDesktopIcons(desktopIcons)}

                <Window props={{
                    icon: require('../../static/imgs/windowsIcons/heart.png'),
                    windowTitle: "Merch",
                    style: {
                        width: "75vw",
                        height: "55vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'scroll',
                    }
                }} >
                    {loading ?
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>

                            <SearchBar />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: 'white' }}>
                                <div style={{ padding: '10px', cursor: 'pointer' }} onClick={() => toggleSidebar()}>
                                    <div className='blackLine'></div>
                                    <div className='blackLine'></div>
                                    <div className='blackLine'></div>
                                </div>
                                <p className="shopifyTitle text-underlined">J R N Y MERCH :)</p>
                                <div style={{ padding: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
                                    <div className="borderSpinning">
                                        {<CartModal
                                            props={{
                                                cart: lines,
                                                linesRemove: linesRemove,
                                                handleCheckout: handleCheckout,
                                                count: totalQuantity
                                            }} />}





                                    </div>
                                </div>

                            </div>

                            <div className="ShopifyPage">

                                {(toggleMenu) &&
                                    <div className="leftSide" style={{}}>
                                        <Sidebar />
                                    </div>
                                }

                                <div className="productList">
                                    <Backdrop
                                        sx={(theme) => ({ color: '#fff', zIndex: 99 })}
                                        open={open}
                                        onClick={toggleSidebar}

                                    >

                                    </Backdrop>
                                    <Snackbar
                                        open={snackbarOpen}
                                        autoHideDuration={3000}
                                        onClose={handleSnackBarClose}
                                        message="Added product to cart"
                                    />
                                    {getListOfProducts()}
                                </div>
                                {width > 1200 &&
                                    <div className="rightSide">
                                        <div className="margin-bottom-L margin-top-L"><img alt="ad1" src={ad6}></img></div>
                                        <div className="margin-bottom-L margin-top-L "><img alt="ad2" src={ad2}></img></div>
                                        <div className="margin-bottom-L margin-top-L "><img alt="ad3" src={ad3}></img></div>
                                    </div>
                                }
                            </div>
                        </>}
                </Window>
            </div>
            <Footer />

        </>
    );
}