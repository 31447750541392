import React, { useState } from 'react'


const SearchBar = () => {

  const [searchInput, setSearchInput] = useState("");


  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };


  return <div>

    <input
      className="searchBar"
      type="search"
      placeholder="www.Jrny.Radio/more"
      onChange={handleChange}
      value={searchInput} />

  </div>


};

export default SearchBar;