import Home from './pages/Home/Home';
import Music from './pages/Music/index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Videos from './pages/Videos/Videos';
import Merch from './pages/Shopify';
import Vlog from './pages/Vlog/vlog';

function App() {


  return (
    <div className="App">
      <div className="HomeBckg">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/merch" element={<Merch />} />
            <Route exact path="/videos" element={<Videos />} />
            <Route exact path="/music" element={<Music />} />
            <Route exact path="/vlog" element={<Vlog />} />
          </Routes>
        </Router>
      </div>
    </div>


  );
}

export default App;
