const GET_PRODUCTS_QUERY = `
  query {
    products(first: 250) {
        edges {
          node {
            id
            title
            description
            availableForSale  
            priceRange{
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  price {
                    amount
                    currencyCode
                  }
                  availableForSale
                  sku
                }
              }
            }
            media(first: 10) {
              edges {
                node {
                  alt
                  mediaContentType
                  ... on MediaImage {
                    image {
                      originalSrc
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;


const GET_PRODCUT_QUERY = `
  query ($id: ID!) {
    product(id: $id) {
      id
      title
      description
      availableForSale
      variants(first: 10) {
        edges {
          node {
            id
            title
            price {
              amount
              currencyCode
            }
            availableForSale
            sku
          }
        }
      }
      media(first: 10) {
        edges {
          node {
            alt
            mediaContentType
            ... on MediaImage {
              image {
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`;

export {
  GET_PRODUCTS_QUERY,
  GET_PRODCUT_QUERY,
};